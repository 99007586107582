import * as React from "react"
import * as styles from "./tooltip.module.css"

const Tooltip = ({ children, text, ...rest }) => {
  const [show, setShow] = React.useState(false)

  return (
    <div className={styles.tooltipContainer}>
      <div className={styles.tooltip + (show ? " block" : " hidden")}>{text}</div>
      <div
        onMouseOver={() => setShow(true) }
        onMouseLeave={() => setShow(false) }
        {...rest}
      >
        {children}
      </div>
    </div>
  )
}

export default Tooltip
