import * as React from "react"
import { Link } from "gatsby"

const Navlink = ({ to, children }) => (
  <Link className="p-6 text-white inline-block w-full h-full hover:text-red uppercase tracking-widest" to={to}>
    <span>{children}</span>
  </Link>
)

export default Navlink
