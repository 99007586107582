import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const CardImage = ({ image, alt }) => (
  <GatsbyImage
    image={image.childImageSharp.gatsbyImageData}
    alt={alt}
    className="w-full max-h-full"
  />
)

export default CardImage
