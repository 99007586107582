import * as React from "react"

import Header from "../header/header"

const Layout = ({ children }) => (
  <div className="bg-gray-dark min-h-full">
    <Header />
    <main>{children}</main>
    <footer>dolna footer</footer>
  </div>
)

export default Layout
