import * as React from "react"

const ExtLink = ({ children, className, ...rest }) => {
  if(typeof className === "undefined") {
    className = ""
  }

  className += " text-red hover:text-white"

  return (<a className={className} {...rest}>{children}</a>)
}

export default ExtLink
