import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Tooltip from "../tooltip/tooltip"

const tagImages = {
  "garden": (
    <StaticImage
      src="../../images/tag-garden.png"
      alt="С градина"
      imgStyle={{objectFit: 'contain'}}
      width={30}
      height={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
  ),
  "moonshine": (
    <StaticImage
      src="../../images/tag-moonshine.png"
      alt="Домашен алкохол"
      imgStyle={{objectFit: 'contain'}}
      width={30}
      height={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
  ),
  "non-stop": (
    <StaticImage
      src="../../images/tag-non-stop.png"
      alt="Работи до късно"
      imgStyle={{objectFit: 'contain'}}
      width={30}
      height={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
  ),
  "vegan": (
    <StaticImage
      src="../../images/tag-vegan.png"
      alt="Вегански ястия"
      imgStyle={{objectFit: 'contain'}}
      width={30}
      height={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
  ),
  "smoking": (
    <StaticImage
      src="../../images/tag-smoking.png"
      alt="Пуши се"
      imgStyle={{objectFit: 'contain'}}
      width={30}
      height={30}
      quality={95}
      formats={["AUTO", "WEBP", "AVIF"]}
    />
  ),
}

const PubTag = ({ tag }) => {
  return (
    <Tooltip text={tag.Name}>
      {tagImages[tag.Slug]}
    </Tooltip>
  )
}

export default PubTag
