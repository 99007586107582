import * as React from "react"

import {Link} from "gatsby"

const IntLink = ({ children, className, ...rest }) => {
  if(typeof className === "undefined") {
    className = ""
  }

  className += " text-red hover:text-white"

  return (<Link className={className} {...rest}>{children}</Link>)
}

export default IntLink
