import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Tooltip from "../tooltip/tooltip"

const categoryName = {
  "Ambience": "Амбианс",
  "Food": "Мезета",
  "Host": "Кръчмар",
  "Booze": "Лее се",
  "Budget": "Бюджетна?",
}

const categoryTooltip = {
  "Ambience": "Качествената обстановка и атмосфера винаги допринасят за пивко време.",
  "Food": "Какво са питиетата без добре приготвени класни вкусотии?",
  "Host": "Личността на един долен кръчмар може да озари и най-мрачния бардак.",
  "Booze": "Важен коефициент за количеството и качеството на изпития алкохол за единица време(ml/h).",
  "Budget": "До колко можем да си позволим благата на тази кръчма? Повече звезди - по-евтино!",
}

const Stars = ({ count }) => {
  let classes= "icon-star ml-1"
  let stars = []
  for(let i = 1; i <= 5; i++) {
    stars.push(<span key={i} className={classes + ((i < count) ? '' : ' text-gray-light' )}/>)
  }
  return (<div className="flex flex-wrap content-center">{stars}</div>)
}

const CategoryScore = ({ name, score }) => {
  let image = null
  switch(name) {
    case "Ambience":
      image = (<StaticImage src="../../images/category-Ambience.png" height={20} alt={categoryName.Ambience} />)
      break;
    case "Food":
      image = (<StaticImage src="../../images/category-Food.png" height={20} alt={categoryName.Food} />)
      break;
    case "Host":
      image = (<StaticImage src="../../images/category-Host.png" height={20} alt={categoryName.Host} />)
      break;
    case "Booze":
      image = (<StaticImage src="../../images/category-Booze.png" height={20} alt={categoryName.Booze} />)
      break;
    case "Budget":
      image = (<StaticImage src="../../images/category-Budget.png" height={20} alt={categoryName.Budget} />)
      break;
    default:
      image = null;
      break;
  }
  return (
    <Tooltip text={categoryTooltip[name]}>
        <div className="flex-grow flex flex-grow text-red font-bold uppercase px-8 border-r border-gray-medium leading-8">
          <div className="flex-grow">
            {image}
            <span className="align-middle ml-4">{categoryName[name]}</span>
          </div>
          <Stars count={score}/>
        </div>
    </Tooltip>
  )
}

const LargeCategoryScore = ({name, score}) => {
  let image = null
  switch(name) {
    case "Ambience":
      image = (<StaticImage src="../../images/category-Ambience.png" height={45} alt={categoryName.Ambience} />)
      break;
    case "Food":
      image = (<StaticImage src="../../images/category-Food.png" height={45} alt={categoryName.Food} />)
      break;
    case "Host":
      image = (<StaticImage src="../../images/category-Host.png" height={45} alt={categoryName.Host} />)
      break;
    case "Booze":
      image = (<StaticImage src="../../images/category-Booze.png" height={45} alt={categoryName.Booze} />)
      break;
    case "Budget":
      image = (<StaticImage src="../../images/category-Budget.png" height={45} alt={categoryName.Budget} />)
      break;
    default:
      image = null;
      break;
  }
  return (
    <Tooltip text={categoryTooltip[name]}>
        <div className="flex-grow gap-4 flex full-width text-red font-bold uppercase leading-8">
          <div>{image}</div>
          <div className="flex-grow flex flex-wrap content-center">{categoryName[name]}</div>
          <Stars count={score}/>
        </div>
    </Tooltip>
  )
}

export { LargeCategoryScore, CategoryScore }
export default CategoryScore
