import * as React from "react"

import { Link } from "gatsby"
import Navlink from "../navlink/navlink"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => {
  const [show, setShow] = React.useState(false)

  let menuContainerClasses = "flex-grow pt-6 w-full lg:flex lg:items-center lg:w-auto lg:block lg:pt-0"
  menuContainerClasses += show ? " block" : " hidden"
  
  return (
    <header className="border-b border-gray-medium">
      <nav className="flex px-5 py-1 justify-between flex-wrap w-full">
        <div className="flex-shrink-0">
          <Link to="/">
            <StaticImage
              className="h-16 lg:h-24"
              src="../../images/dolna-logo.png"
              alt="Долно лого"
              imgStyle={{objectFit: 'contain'}}
              height={60}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
            />
          </Link>
        </div>

  		  <div className="block lg:hidden">
			    <button id="nav-toggle" aria-label="отваряне/затваряне на навигацията" onClick={() => setShow(!show)} className="flex items-center px-3 py-2 text-gray-light">
				    <svg className="fill-current h-12 w-12" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	            <title>Menu</title>
	            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
			    </button>
		    </div>

        <div className={menuContainerClasses}>
          <ul className="flex text-center w-full flex-col justify-center w-full items-center lg:flex-row lg:justify-end">
            <li className="w-full lg:w-auto"><Navlink to="/">Долно начало</Navlink></li>
            <li className="w-full lg:w-auto"><Navlink to="/dolna-lista">Долна листа</Navlink></li>
            <li className="w-full lg:w-auto"><Navlink to="/dolni-piqnitzi">Долни пияници</Navlink></li>
            <li className="w-full lg:w-auto"><Navlink to="/dolni-predlozheniq">Долни предложения</Navlink></li>
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default Header
